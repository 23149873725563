<template>
    <v-app>
        <v-main>
            <v-container
                    class="fill-height"
                    style="background-color: lightgrey"
                    fluid
            >
                <loading :active.sync="isLoading"
                         :can-cancel="false"
                         :is-full-page="true"></loading>
                <v-row
                        align="center"
                        justify="center">
                    <v-col
                            cols="12"
                            sm="8"
                            md="4">
                        <v-card
                                elevation="5">
                            <form @submit.prevent="operatorLogin">
                                <v-toolbar
                                        color="warning"
                                        dark
                                        flat>
                                    <v-toolbar-title> Пицца Ник. Оператор заказов</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                </v-toolbar>
                                <v-card-text>

                                    <v-text-field
                                            v-model="login"
                                            label="Имя пользователя"
                                            name="login"
                                            prepend-icon="mdi-account"
                                            type="text"
                                            @input="$v.login.$touch()"
                                            :error-messages="loginErrorMessages"
                                            required
                                    ></v-text-field>

                                    <v-text-field
                                            v-model="password"
                                            label="Пароль"
                                            prepend-icon="mdi-lock"
                                            type="password"
                                            @input="$v.password.$touch()"
                                            :error-messages="passwordErrorMessages"
                                            required></v-text-field>

                                    <v-snackbar
                                            v-model="showErrorSnackbar"
                                            :multi-line="true"
                                            absolute
                                            bottom>
                                        {{ errorMessage }}
                                        <template>
                                            <v-btn
                                                    color="red"
                                                    text
                                                    @click="showErrorSnackbar = false">
                                                Закрыть
                                            </v-btn>
                                        </template>
                                    </v-snackbar>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                            large
                                            type="submit"
                                            color="warning"
                                            @click="operatorLogin">Войти
                                    </v-btn>
                                </v-card-actions>
                            </form>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    import {mapActions} from 'vuex'
    import {validationMixin} from 'vuelidate'
    import {required} from 'vuelidate/lib/validators'
    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        name: 'Login',
        mixins: [validationMixin],
        data: () => ({
            login: '',
            password: '',
            showErrorSnackbar: false,
            errorMessage: '',
            isLoading: false
        }),
        validations: {
            login: {required},
            password: {required}
        },
        computed: {
            loginErrorMessages() {
                const errors = []
                if (!this.$v.login.$dirty) {
                    return errors
                }
                !this.$v.login.required && errors.push('Введите имя пользователя')
                return errors
            },
            passwordErrorMessages() {
                const errors = []
                if (!this.$v.password.$dirty) {
                    return errors
                }
                !this.$v.password.required && errors.push('Введите пароль')
                return errors
            }
        },
        methods: {
            ...mapActions(['webAuth']),
            operatorLogin() {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    return;
                }

                this.isLoading = true

                this.webAuth({login: this.login, password: this.password}).then((response) => {
                    this.isLoading = false
                    if (!response.result) {
                        this.showErrorSnackbar = true
                        this.errorMessage = response.errorMessage
                        return
                    }

                    if (this.$router.currentRoute.name !== 'Queue') {
                        this.$router.push('/queue')
                    }
                })
            }
        },
        components: {
            Loading
        }
    }
</script>
